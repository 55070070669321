import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const languageApi = createApi({
    reducerPath: 'languageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getLanguage: builder.query({
            query: () => `/language`,
        })
    }),
});

export const { useGetLanguageQuery } = languageApi;

