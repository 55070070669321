import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const brandTypeApi = createApi({
    reducerPath: 'brandType',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getBrandType: builder.query({
            query: (language) => ({
                url: `/brand-type`,
                headers: {
                    "accept-language": language
                },
            }),
        })
    }),
});

export const { useGetBrandTypeQuery } = brandTypeApi;

