import { createSlice } from '@reduxjs/toolkit';


export const staticTextsSlice = createSlice({
    name: 'staticTexts',
    initialState: {
        staticTexts: [],
    },
    reducers: {
        getStaticTexts: (state,action,language) => {
            // console.log(action,'action')
            state.staticTexts = action.payload;
        },
    },
});

export const {getStaticTexts} = staticTextsSlice.actions;

export default staticTextsSlice.reducer;
