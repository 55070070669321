import './blog.scss'
import PagesHeader from "../../components/pagesHeader/PagesHeader";
import {PageLeftArrIcon, PageRightArrIcon} from "../../assets/images";
import BlogCard from "../../components/uiElements/cards/blogCard/BlogCard";
import {Pagination} from "antd";
import Subscribe from "../../components/subscribe/Subscribe";
import {useGetBlogQuery} from "../../redux/service/blog";
import {useState,useEffect} from "react";
import {useSelector} from "react-redux";


function Blog() {
    const [blogPage, setBlogPage] = useState(1);
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: blogDate} = useGetBlogQuery({language:selectedLanguage,offset: (blogPage - 1) * 9, limit:9})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PageLeftArrIcon/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PageRightArrIcon/>
            </a>;
        }
        return originalElement;
    };

    const onChangePaging = (page) => {
        setBlogPage(page)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return <div className={'blog-page-wrapper'}>
        <PagesHeader title={`${staticTexts?.blog_page_title}`}
                     description={`${staticTexts?.blog_page_description}`}/>

        <div className={'blogs-wrapper'}>
            {
                blogDate?.blogs?.length &&  blogDate?.blogs?.map((item, index) => {
                    return <div className={'blog-item'} key={index}>
                        <BlogCard item={item}/>
                    </div>
                })
            }
        </div>
        <Pagination current={blogPage}
                    defaultPageSize={9}
                    onChange={onChangePaging}
                    itemRender={itemRender}
                    hideOnSinglePage={true}
                    className={`blogs-pagination`}
                    total={blogDate?.blogsCount || 0}/>
        <div className={'blog-subscribe'}>
            <Subscribe/>
        </div>
    </div>
}

export default Blog