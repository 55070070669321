import { createSlice } from '@reduxjs/toolkit';


export const blogSlice = createSlice({
    name: 'blog',
    initialState: {
        blogList: [],
    },
    reducers: {
        getBlog: (state,action) => {
            state.blogList = action.payload;
        },
    },
});

export const {getBlog} = blogSlice.actions;

export default blogSlice.reducer;
