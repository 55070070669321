import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const subscribeApi = createApi({
    reducerPath: 'subscribeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        subscribe: builder.mutation({
            query: (payload) => ({
                url: '/subscriber',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const { useSubscribeMutation } = subscribeApi;
