import './aboutUs.scss'
import {ArrIcon} from "../../../assets/images";
import {useGetAboutQuery} from "../../../redux/service/about";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";



function AboutUs({language,staticTexts}){
    const {data: about} = useGetAboutQuery(language)

    return <div className={'home-about-wrapper'}>
        <div className={'home-about-left-block'}>
            <div className={'home-about-title'}>
                {about?.title}
            </div>
            <div className={'home-about-description'}>
                {about?.description}
            </div>
            <Link to={'/about'} className={'home-about-link'}>
                {staticTexts?.home_more} <ArrIcon/>
            </Link>
        </div>
        <div className={'home-about-right-block'}>
            <div className={'home-about-img'}>
                <img src={generateMediaUrl(about?.secondaryMedia?.path)} alt=""/>
            </div>
        </div>
    </div>

}export default AboutUs