import './footer.scss'
import {ArrIcon, FacebookIcon, FooterFacebookIcon, FooterLogo, InstagramIcon, LangeArrIcon} from "../../assets/images";
import {Link} from "react-router-dom";
import {useGetLanguageQuery} from "../../redux/service/language";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, Menu, Space} from "antd";
import {changeLanguage} from "../../redux/slices/languageSlice";
import {useGetContactQuery} from "../../redux/service/contact";


function Footer() {
    const {data: language} = useGetLanguageQuery()
    const brandes = useSelector((state) =>  state.brand.brandeList)
    const selectedLanguage = useSelector((state) => state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: contact} = useGetContactQuery(selectedLanguage)
    const dispatch = useDispatch()

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                language?.map((code) => {
                    return selectedLanguage !== code?.code && <Menu.Item key={code?.id}
                                                                         onClick={() => dispatch(changeLanguage(code?.code))}
                                                                         className={'language-dropdown-item'}>
                        <div className="selected-language">
                            <span>{code?.name}</span>
                        </div>
                    </Menu.Item>
                })
            }
        </Menu>
    );

    return <div className={'footer-wrapper'}>
        <Link to={'/'} className={'responsive-footer-link'}>
            {staticTexts?.header_name}
            <div className={'responsive-footer-logo-text'}>
                <span className={'line'}/>
                <span className={'country'}>{staticTexts?.header_name_country}</span>
                <span className={'line'}/>
            </div>
        </Link>
        <div className={'footer-top-block'}>
            <Link to={'/'} className={'footer-logo'}>
                <FooterLogo/>
            </Link>
            <div className={'col'}>
                <Link to={'/'}>{staticTexts?.header_item_home}</Link>
                <Link to={'/brands'}>{staticTexts?.header_item_brands}</Link>
                <Link to={'/blog'}>{staticTexts?.header_item_blog}</Link>
                <Link to={'/about'}>{staticTexts?.header_item_about}</Link>
                <Link to={'/contact'}>{staticTexts?.header_item_contact}</Link>
            </div>
            <div className={'col'}>
                <div className={'footer-brands-title'}>
                    {staticTexts?.footer_brands_title}
                </div>
                {
                    brandes?.brands?.slice(0,5)?.map((item, index) => {
                        return index > 3 ? <Link to={'/brands'} className={'footer-brand-all'} key={index}>
                            See All
                            <ArrIcon/>
                        </Link> : <Link to={`/brands/${item?.slug}`} className={'footer-brand-name'} key={index}>
                            {item?.title}
                        </Link>
                    })
                }
            </div>
            <div className={'col'}>
                <div className={'medias-wrapper'}>
                    <div className={'footer-medias-title'}>
                        {staticTexts?.footer_medias_title}
                    </div>
                    <div className={'footer-medias'}>
                        <a href={contact?.facebook} target={'_blank'}>
                            <FooterFacebookIcon/>
                        </a>
                        <a href={contact?.instagram} target={'_blank'}>
                            <InstagramIcon/>
                        </a>
                    </div>
                </div>
                <div className={'footer-lang'}>
                    <Dropdown
                        trigger={['click']}
                        overlay={menuLanguage}
                        destroyPopupOnHide={false}
                        autoFocus={false}
                        autoAdjustOverflow={false}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className="selected-language">
                                    {language?.map(code => {
                                        return selectedLanguage === code?.code && code?.name
                                    })} <LangeArrIcon/>
                                </div>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
        </div>
        <div className={'footer-bottom-block'}>
            {staticTexts?.footer_cooperation} <a href={'https://solidy.am/'} target={'_blank'}>Solidy.am</a>
        </div>
    </div>

}

export default Footer