import './mainSlider.scss'
import {LeftArrIcon, RightArrIcon} from "../../../assets/images";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Navigation} from "swiper/modules";
import {useState} from "react";
import {useGetSliderQuery} from "../../../redux/service/slider";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";


function MainSlider({language}){
    const [slideIndex, setSlideIndex] = useState(0);
    const {data: slider} = useGetSliderQuery(language)


    const nextSlide = (index) => {
        setSlideIndex(index + 1 === slider?.length ? 0 : index + 1)
    };

    const prevSlide = (index) => {
        setSlideIndex(index !== 0 ? 0 : index - 1)
    };

    // console.log(slider,'sliderslider')

    return <div className={'main-slider-wrapper'}>
        <Swiper
            spaceBetween={'25'}
            effect={'fade'}
            slidesPerView={1}
            pagination={{
                clickable: true,
            }}
            loop={true}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
            }}
            navigation={false}
            freeMode={true}
            modules={[Autoplay,EffectFade, Navigation]}
        >
            {slider?.length && slider?.map((item,index) => {
                if(index === slideIndex){
                   return <SwiperSlide key={item.id}>
                       <div className="slider-item" key={index} >
                           <div className={'slider-img'}>
                               <img src={generateMediaUrl(item?.mediaMain?.path)} alt=""/>
                           </div>
                       </div>
                   </SwiperSlide>
               }
            })}
        </Swiper>
        {
            slider?.length > 1 &&  <div className={'slider-buttons-block'}>
                <button className={`slider-btn  prev-btn`}
                        onClick={() => nextSlide(slideIndex)}
                >
                    <LeftArrIcon/>
                </button>
                <button
                    className={`slider-btn next-btn`}
                    onClick={() => nextSlide(slideIndex)}>
                    <RightArrIcon/>
                </button>
            </div>
        }
    </div>

}export default MainSlider