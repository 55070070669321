import './contact.scss'
import PagesHeader from "../../components/pagesHeader/PagesHeader";
import {AddressIcon, EmailIcon, PhoneIcon} from "../../assets/images";
import {useContactMutation, useGetContactQuery} from "../../redux/service/contact";
import {useSelector} from "react-redux";
import {useState,useEffect} from "react";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "../../components/uiElements/input-group/InputGroup";
import Subscribe from "../../components/subscribe/Subscribe";
import { LoadingOutlined } from "@ant-design/icons";


function Contact() {
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: contact} = useGetContactQuery(selectedLanguage)
    const [sendContact,{ isLoading }] = useContactMutation()
    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        message: false,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function validate () {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && !/^[+374]{4}[0-9]{8}$/.test(fieldsData[key]))) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function getInputValues (e) {
        let { name, value } = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (name === "phone" && value.length === 1 && value !== "+") {
            value = "+" + value
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const sendMessage = () => {
        const payload = {
            firstname: fieldsData.firstName,
            lastname: fieldsData.lastName,
            email: fieldsData.email,
            phone: fieldsData.phone,
            message: fieldsData.message,
        }
        if (validate()) {
            sendContact(payload).catch((error) => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                })
            })
        }
    }

    return <div className={'contact-page-wrapper'}>
        <PagesHeader title={staticTexts?.contact_page_title}
                     description={staticTexts?.contact_page_description}/>

        <div className={'contacts-block'}>
            <div className={'contact-info-wrapper'}>
                <div className={'contact-info-title'}>
                    {staticTexts?.contact_page_info_title}
                </div>
                <div className={'contact-info-description'}>
                    {staticTexts?.contact_page_info_description}
                </div>
                <a className={'contact-phone-block'} href={`tel:+${contact?.phoneNumber}`}>
                    <PhoneIcon/>
                    <span>{`${contact?.phoneNumber}`}</span>
                </a>
                <a className={'contact-email-block'} href={`mailto:${contact?.email}`}>
                    <EmailIcon/> {contact?.email}
                </a>
                <div className={'contact-address-block'}>
                    <AddressIcon/> {contact?.address}
                </div>
            </div>
            <div className={'contact-inputs-wrapper'}>
                <div className={'top-input-block'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.firstName}
                        error={error.firstName}
                        name={'firstName'}
                        placeholder={staticTexts?.contact_page_firstName_placeholder}
                        label={staticTexts?.contact_page_firstName_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.lastName}
                        error={error.lastName}
                        name={'lastName'}
                        placeholder={staticTexts?.contact_page_lastName_placeholder}
                        label={staticTexts?.contact_page_lastName_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                </div>
                <div className={'mid-input-block'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.email}
                        error={error.email}
                        name={'email'}
                        placeholder={staticTexts?.contact_page_email_placeholder}
                        label={staticTexts?.contact_page_email_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.phone}
                        error={error.phone}
                        name={'phone'}
                        placeholder={staticTexts?.contact_page_phone_placeholder}
                        label={staticTexts?.contact_page_phone_label}
                        maxLength={12}
                        onChange={getInputValues}
                    />
                </div>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.message}
                    error={error.message}
                    name={'message'}
                    placeholder={staticTexts?.contact_page_message_placeholder}
                    label={staticTexts?.contact_page_message_label}
                    onChange={getInputValues}
                />
                <button className='sent-email'
                        onClick={() => sendMessage()}>
                    {staticTexts?.contact_page_send_btn} {isLoading ?
                    <LoadingOutlined style={{ fontSize: 14, color: "#2E301B" }}/> : ''}
                </button>
            </div>
        </div>
        <div className={'contact-subscribe-block'}>
            <Subscribe/>
        </div>
    </div>
}

export default Contact