import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";
import {useSelector} from "react-redux";

export const sliderApi = createApi({
    reducerPath: 'sliderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getSlider: builder.query({
            query: (language) => ({
                url:`/slider`,
                headers: {
                    "accept-language": language
                },
            }),
        })
    }),
});

export const { useGetSliderQuery } = sliderApi;

