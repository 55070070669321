import './products.scss'
import {Pagination} from "antd";
import {
    CheckboxIcon,
    FilterCloseIcon, OpenIcon,
    PageLeftArrIcon,
    PageRightArrIcon,
} from "../../../../assets/images";
import ProductCard from "../../../../components/uiElements/cards/productCard/ProductCard";
import Partners from "../../../../components/homepages/partners/Partners";
import BestSellerSlider from "../../../../components/uiElements/bestsellersSlider/BestSellerSlider";
import {Link, useParams} from "react-router-dom";
import {useGetBrandByIdQuery} from "../../../../redux/service/brand";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {useGetFilterQuery} from "../../../../redux/service/filter";
import {useGetProductQuery,useGetProductCountQuery} from "../../../../redux/service/product";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProduct} from "../../../../redux/slices/productSlice";
import {Autoplay, EffectFade} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {getPage} from "../../../../redux/slices/pageSlice";
import {LoadingOutlined} from "@ant-design/icons";

function Product() {
    const param = useParams()
    const mainRef = useRef(null);
    const productPages = useSelector((state) => state.page.productPage)
    const [productPage, setProductPage] = useState(productPages);
    const [filterId, setFilterId] = useState([]);
    const [openFilterId, setOpenFilterId] = useState('')
    const dispatch = useDispatch()
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const {data: brandById} = useGetBrandByIdQuery({id:param?.slug,language:selectedLanguage})
    const {data: productsData, isLoading } = useGetProductQuery(
        {language:selectedLanguage,brand: brandById?.id, offset: (productPage - 1) * 9, limit:9,filter:filterId,category:param?.id}
    )
    const {data: productsCount} = useGetProductCountQuery({language:selectedLanguage,brand: brandById?.id,filter:filterId,category:param?.id})
    const {data: filters} = useGetFilterQuery(selectedLanguage)
    const bestSeller = useSelector((state) => state.product.bestSellerProduct)
    const product = window.innerWidth < 1050 ? productsData?.slice(0, 6) : productsData
    // console.log(isLoading,'productsDataproductsData')


    useEffect(() => {
        productsData &&  dispatch(getProduct(productsData))
    },[productsData])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[brandById?.id])

    useEffect(() => {
        window.scrollTo({
            top: mainRef?.current?.clientHeight,
            behavior: "smooth"
        });
    },[productPage])

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PageLeftArrIcon/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PageRightArrIcon/>
            </a>;
        }
        return originalElement;
    };

    const onChangePaging = (page) => {
        setProductPage(page)
        dispatch(getPage(page))
    };

    function openFilter(id){
        setFilterId([...filterId,id])
        const checked = filterId?.find((item) => item === id)
        if(checked){
            setFilterId(filterId?.filter((item) => item !== id))
        }
    }

    function closeFilter () {
        setOpenFilterId('')
        setFilterId([])
    }


    return <div className={'product-wrapper'}>
        <Swiper
            spaceBetween={'25'}
            effect={'fade'}
            ref={mainRef}
            slidesPerView={1}
            pagination={{
                clickable: true,
            }}
            loop={true}
            autoplay={{
                delay: 1000,
                disableOnInteraction: false,
            }}
            freeMode={true}
            modules={[Autoplay,EffectFade]}
        >
            <SwiperSlide>
                <div className="slider-item" >
                    <div className={'products-header'}>
                        <img src={generateMediaUrl(brandById?.coverMedia?.path)} alt=""/>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
        <div className={'product-block'}>
            <div className={'product-response-text'}>
                <div className={'product-title'}>
                    {brandById?.title}
                </div>
                <div className={'product-description'}  dangerouslySetInnerHTML={{__html: brandById?.description}} />
            </div>
            <div className={'product-filter-block'}>
                <div className={'filter-wrapper'}>
                    {filters?.length && filters?.map((item, index) => {
                        return  <div className={'filter-block'} >
                            <div className={'filter-title'}>
                                {item?.title}{
                                openFilterId === item?.id ? <p onClick={closeFilter}>
                                        <OpenIcon />
                                    </p> :
                                    <p onClick={() => setOpenFilterId(item?.id)}>
                                        <FilterCloseIcon/>
                                    </p>
                            }
                            </div>
                            {openFilterId === item?.id ? item?.values?.map(item => {
                                return <div className={'filter-value'} onClick={() => openFilter(item?.id)}>
                                    {filterId?.includes(item?.id) ? <CheckboxIcon/> : '' }{item?.title}
                                </div>
                            }) : ''}
                        </div>
                    })
                    }
                </div>
            </div>
            <div className={'product-list-block'}>
                <div className={'product-info'}>
                    <div className={'product-title'}>
                        {brandById?.title}
                    </div>
                    <div className={'product-description'}  dangerouslySetInnerHTML={{__html: brandById?.description}} />
                </div>
                {
                    !isLoading ? <div>
                        {
                            product?.length ? <div className={'product-list'}>
                                    <div className={'product-list-items'}>
                                        {
                                            product?.map((item, index) => {
                                                return <Link to={`/product/${item?.id}`} className={'product'} key={index}>
                                                    <ProductCard item={item}/>
                                                </Link>
                                            })
                                        }
                                    </div>
                                    <Pagination current={productPage}
                                                defaultPageSize={9}
                                                onChange={onChangePaging}
                                                itemRender={itemRender}
                                                hideOnSinglePage={true}
                                                className={`pagination-product`}
                                                total={productsCount?.productsCount || 0}/>
                                </div> :
                                <div className={'product-empty-block'}>
                                    {staticTexts?.product_empty_text}
                                </div>
                        }
                    </div> : <button>
                        <LoadingOutlined style={{ fontSize: 14, color: "#0c0c0c" }}/>
                    </button>
                }

                {
                    bestSeller?.length ? <BestSellerSlider products={bestSeller}/> : ''
                }
            </div>
        </div>
        <Partners/>
    </div>
}

export default Product