import './header.scss'
import {useState} from "react";
import {BgMenuIcon, LangeArrIcon} from "../../assets/images";
import {Link} from "react-router-dom";
import MobileMenu from "../mobileMenu/MobileMenu";
import {useGetLanguageQuery} from "../../redux/service/language";
import {Dropdown,Menu,Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {changeLanguage} from "../../redux/slices/languageSlice";


function Header(){
    const [openMenu,setOpenMenu] = useState()
    const {data: language} = useGetLanguageQuery()
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const dispatch = useDispatch()

    function toggleMobileMenu () {
        setOpenMenu(!openMenu)
    }

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                language?.map((code) => {
                    return selectedLanguage !== code?.code && <Menu.Item key={code?.id}
                                   onClick={() => dispatch(changeLanguage(code?.code))}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{code?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

    return (
        <div className={'header-wrapper'}>
            <div className={'burger-menu'} onClick={toggleMobileMenu}>
                <BgMenuIcon/>
            </div>
            <Link to={'/'} className={'header-link'}>
                {staticTexts?.header_name}
                <div className={'header-logo-text'}>
                    <span className={'line'}/>
                    <span className={'country'}>{staticTexts?.header_name_country}</span>
                    <span className={'line'}/>
                </div>
            </Link>
            <div className={'lang-wrapper'}>
                <Dropdown
                    trigger={['click']}
                    overlay={menuLanguage}
                    destroyPopupOnHide={false}
                    autoFocus={false}
                    autoAdjustOverflow={false}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <div className="selected-language">
                                <span>
                                    {language?.map(code => {
                                        return selectedLanguage === code?.code && code?.name
                                    })}
                                </span> <LangeArrIcon/>
                            </div>
                        </Space>
                    </a>
                </Dropdown>
            </div>
            <MobileMenu className={openMenu ? 'expand_on' : ''}
                        mobileMenuOpen={openMenu}
                        toggleMobileMenu={toggleMobileMenu}/>
        </div>
    )

}export default Header