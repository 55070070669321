import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const aboutApi = createApi({
    reducerPath: 'aboutApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getAbout: builder.query({
            query: (language) =>({
                url:`/about`,
                headers: {
                    "accept-language": language
                },
            }) ,
        })
    }),
});

export const { useGetAboutQuery } = aboutApi;

