import './subscribe.scss'
import {useState} from "react";
import {InputGroup} from "../uiElements/input-group/InputGroup";
import {useSubscribeMutation} from "../../redux/service/subscribe";
import isEmail from "validator/es/lib/isEmail";
import {useSelector} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";


function Subscribe(){
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [subscribe,{ isLoading }] = useSubscribeMutation()

    const getInputValues = (e) => {
        const {value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            subscribe({email}).then(() => {
                setEmail('')
            }, errorEmail => setError(!errorEmail))
        } else {
            setError(true)
        }
    }

    return <div className={'subscribe-block'}>
        <div className={'subscribe-title'}>
            {staticTexts?.subscribe_title}
        </div>
        <div className={'subscribe-description'}>
            {staticTexts?.subscribe_description}
        </div>
        <div className={'subscribe-input-block'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={email}
                error={error}
                name={'email'}
                placeholder={staticTexts?.subscribe_email_placeholder}
                maxLength={100}
                onChange={getInputValues}
            />

            <button className='sent-email'
                    onClick={sendSubscribe}>
                {staticTexts?.subscribe_subscribe_btn} {isLoading ?
                <LoadingOutlined style={{ fontSize: 14, color: "#fff" }}/> : ''}
            </button>
        </div>
    </div>
}export default Subscribe