import './brands.scss'
import {Pagination, Tabs} from "antd";
import {ArrIcon, ArrWhiteIcon, PageLeftArrIcon, PageRightArrIcon} from "../../assets/images";
import PagesHeader from "../../components/pagesHeader/PagesHeader";
import {Link} from "react-router-dom";
import Partners from "../../components/homepages/partners/Partners";
import {useGetBrandTypeQuery} from "../../redux/service/brand_type";
import {useState,useEffect} from "react";
import {useGetBrandQuery} from "../../redux/service/brand";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import {useSelector} from "react-redux";

function Brandes(){
    const [brandPage, setBrandPage] = useState(1);
    const [activeActiveTab, setActiveActiveTab] = useState(null)
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: brandType} = useGetBrandTypeQuery(selectedLanguage)
    const {data: brand} = useGetBrandQuery({type:activeActiveTab,language:selectedLanguage,offset: (brandPage - 1) * 9, limit:9})
    const onChange = (key) => {
        setActiveActiveTab(key)
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    const brandes =[
        {
            key: null,
            label: `${staticTexts?.brandes_page_tab_all}`,
        },
    ]
    brandType?.forEach(c => {
        brandes?.push({
            key: c.id,
            label: c.title,
        })
    })

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PageLeftArrIcon/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PageRightArrIcon/>
            </a>;
        }
        return originalElement;
    };

    const onChangePaging = (page) => {
        setBrandPage(page)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    return <div className={'brandes-wrapper'}>
        <PagesHeader title={`${staticTexts?.brandes_page_title}`}
                     description={`${staticTexts?.brandes_page_description}`}/>

        <div className={'brandes-filter-block'}>
            <Tabs defaultActiveKey={1} items={brandes} onChange={onChange} />
            <div className={'brands-block'}>
                {
                    brand?.brands?.map((item,index) => {
                        return <Link to={`/brands/${item?.slug}`} className={'brand'} key={index}>
                            <div className={'brand-img'}>
                                <img src={generateMediaUrl(item?.mediaMain?.path)} alt=""/>
                            </div>
                            <div className={'more-content'}>
                                <div className={"text-count"}>{staticTexts?.brandes_page_more}   <ArrWhiteIcon/></div>
                            </div>
                        </Link>
                    })
                }
            </div>
            <Pagination current={brandPage}
                        defaultPageSize={9}
                        onChange={onChangePaging}
                        itemRender={itemRender}
                        hideOnSinglePage={true}
                        className={`brand-pagination`}
                        total={brand?.brandCount || 0}/>
        </div>
        <Partners/>
    </div>
}export default Brandes