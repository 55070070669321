import { useEffect, useState } from "react";

//Assets

//Components
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

import MobileMenu from "../components/mobileMenu/MobileMenu";
import {useGetBrandQuery} from "../redux/service/brand";
import {useDispatch, useSelector} from "react-redux";
import {getBrande} from "../redux/slices/brandeSlice";
import {getLanguage} from "../redux/slices/languageSlice";
import {useGetBlogQuery} from "../redux/service/blog";
import {getBlog} from "../redux/slices/blogSlice";
import {useGetStaticTextsQuery} from "../redux/service/static-texts";
import {getStaticTexts} from "../redux/slices/staticTextSlice";

//Hooks


function Layout (props) {
    const { children } = props
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    function toggleMobileMenu () {
        setMobileMenuOpen(!mobileMenuOpen)
    }
    const dispatch = useDispatch()
    const {data: staticTexts} = useGetStaticTextsQuery()
    const selectedLanguage = useSelector((state) => state.language.selectedLanguage)
    const {data: brand} = useGetBrandQuery({language:selectedLanguage})
    const {data: blogDate} = useGetBlogQuery({language:selectedLanguage,limit:4})


    useEffect(() => {
        staticTexts && dispatch(getStaticTexts(staticTexts[selectedLanguage]))
    },[selectedLanguage,staticTexts])

    useEffect(() => {
        blogDate && dispatch(getBlog(blogDate))
    },[blogDate])

    useEffect(() => {
        brand && dispatch(getBrande(brand))
    },[brand])



    return <div className={'layout-wrapper'}>
        <Header mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}/>
        <MobileMenu className={mobileMenuOpen ? 'expand_on' : ''}
                    mobileMenuOpen={mobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}/>
        <div>
            {children}
        </div>
        <Footer mobileMenuOpen={mobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}/>
    </div>
}

export default Layout
