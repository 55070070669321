import './productDetail.scss'
import BestSellerSlider from "../../../../../components/uiElements/bestsellersSlider/BestSellerSlider";
import Partners from "../../../../../components/homepages/partners/Partners";
import {Tabs} from "antd";
import {useState, useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import {useParams} from "react-router-dom";
import {useGetProductByIdQuery} from "../../../../../redux/service/product";
import {generateMediaUrl} from "../../../../../utils/generateMediaUrl";
import {useSelector} from "react-redux";
import ImagesSlider from "../../../../../components/uiElements/modal/imagesSlider/ImagesSlider";
import {ArrowRightIcon, PlaceholderImg} from "../../../../../assets/images";

function ProductDetail() {
    const param = useParams()
    const [sliderOpen, setSliderOpen] = useState(false)
    const [imagePathList, setImagePathList] = useState([])
    const [activeTab, setActiveTab] = useState( '')
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const selectedLanguage = useSelector((state) => state.language.selectedLanguage)
    const staticTexts = useSelector((state) => state.staticTexts.staticTexts)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const {data: product} = useGetProductByIdQuery({id: param?.id, language: selectedLanguage})
    const [description, setDescription] = useState(product?.description)
    const bestSeller = useSelector((state) => state.product.bestSellerProduct)
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = (index) => {
        setSlideIndex(index + 1 === product?.medias?.length ? 0 : index + 1)
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
    };
    const onChange = (key) => {
        setActiveTab(key)
        if (key === 'brand') {
            setDescription(product[key]?.description)
        } else {
            setDescription(product[key])
        }
    };

    useEffect(()=>{
        if(product){
            onChange(activeTab)
        }
    },[selectedLanguage,activeTab])

    function openImagesSlider(modalImagePathList, imageIndex) {
        setSliderOpen(true)
        setCurrentImageIndex(imageIndex)
        setImagePathList(modalImagePathList)
    }

    function closeImagesSlider() {
        setSliderOpen(false)
        setCurrentImageIndex(0)
        setImagePathList([])
    }

     const options =[
         {
            key: 'description',
            label: `${staticTexts?.product_detail_tab_description}`,
        },
         {
            key: 'utilize',
            label: `${staticTexts?.product_detail_tab_utilize}`,
        },
         {
            key: 'brand',
            label: `${staticTexts?.product_detail_tab_brand}`,
        },
         {
            key: 'ingredients',
            label: `${staticTexts?.product_detail_tab_ingredients}`,
        } ,
    ]

    const infos =[]
    product && options?.filter(item => {
        if(product[item?.key]){
            infos.push({
                key:item?.key,
                label:item?.label,
            })
        }
    })

    useEffect(() => {
       infos && activeTab || setActiveTab(infos[0]?.key)
    },[product])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [param?.id])


    return <div className={'product-detail-page'}>
        <div className={'product-detail-wrapper'}>
            <div className={'product-detail-slider'}>
                <Swiper
                    spaceBetween={10}
                    navigation={false}
                    thumbs={thumbsSwiper}
                    modules={[FreeMode, Navigation, Thumbs]}
                    style={{
                        '--swiper-navigation-color': '#000',
                        '--swiper-pagination-color': '#000',
                    }}
                    className="mySwiper2"
                    loop={true}
                >
                    {
                        product?.medias?.length ? product?.medias?.map((item, index) => {
                            if (index === slideIndex) {
                                return <SwiperSlide key={index}>
                                    <div className={'slider-single-img'}
                                         onClick={() => product?.medias && openImagesSlider(product?.medias, index)}>
                                        <img src={generateMediaUrl(item?.path)} alt={'img'}/>
                                    </div>
                                </SwiperSlide>
                            }
                        }) : ''
                    }
                </Swiper>
                <div className={'slider-bottom-side'}>
                    {
                        product?.medias?.length > 4  ? <button className={`slider-left-btn`}
                                                          onClick={() => prevSlide(slideIndex)}>
                            <ArrowRightIcon/>
                        </button> : ""
                    }
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                        loop={true}
                    >
                        {
                            product?.medias?.length ? product?.medias?.map((item, index) => {
                                return <SwiperSlide key={index}>
                                    <div className={`slider-bottom-images ${index === slideIndex ? 'active-img' : ''}`}
                                         onClick={() => product?.medias && openImagesSlider(product?.medias, index)}>
                                        <img src={item?.path ? generateMediaUrl(item?.path) : PlaceholderImg}
                                             alt={'img'}/>
                                    </div>
                                </SwiperSlide>
                            }) : <div className={'placeholderImg'}>
                                <img src={PlaceholderImg} alt={'img'}/>
                            </div>
                        }
                    </Swiper>
                    {
                        product?.medias?.length > 4 ? <button
                            className={`slider-right-btn`}
                            onClick={() => nextSlide(slideIndex)}>
                            <ArrowRightIcon/>
                        </button> : ""
                    }
                </div>
            </div>
            <div className={'product-detail-info'}>
                <div className={'product-detail-name'}>
                    {product?.title}
                </div>
                <div className={'product-detail-description'}>
                    {product?.subTitle}
                </div>
                {
                    product ? <Tabs defaultActiveKey={infos[0]} items={infos} onChange={onChange}/> : ''
                }
                {
                    description ?
                        <div className={'product-detail-tabs-info'} dangerouslySetInnerHTML={{__html: description}}/> :
                        <div className={'product-detail-tabs-info'} dangerouslySetInnerHTML={{__html: product?.description}}/>
                }
                <div className={'product-detail-buy-block'}>
                    <div className={'buy-block-title'}>
                        {staticTexts?.product_detail_buy_title}
                    </div>
                    <div className={'buy-block-description'}>
                        {staticTexts?.product_detail_buy_description}
                    </div>
                    <div className={'buy-block-shops'}>
                        {
                            product?.partners?.length ? product?.partners?.map((item, index) => {
                                return <a href={item?.link}
                                          target={'_blank'}
                                          className={'buy-block-img-wrapper'}
                                          key={item?.id}>
                                    <div className={'buy-block-img'}>
                                        <img
                                            src={item?.mediaMain?.path ? generateMediaUrl(item?.mediaMain?.path) : PlaceholderImg}
                                            alt=""/>
                                    </div>
                                </a>
                            }) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
        {
            bestSeller?.length ? <div className={'best-seller-wrapper'}>
                <BestSellerSlider products={bestSeller}/>
            </div> : ''
        }
        <Partners/>
        <ImagesSlider visible={sliderOpen}
                      modalImagePathList={imagePathList}
                      modalCurrentImageIndex={currentImageIndex}
                      closeModal={closeImagesSlider}/>
    </div>
}

export default ProductDetail