import './bestSellerSlider.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import ProductCard from "../cards/productCard/ProductCard";
import {useSelector} from "react-redux";

function BestSellerSlider({products}){
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)

    return <div className={'bestseller-products'}>
        <div className={'bestseller-products-title'}>
            {staticTexts?.bestSeller_title}
        </div>
        <div className={'bestseller-products-list'}>
            <Swiper
                className={'bestseller-slider'}
                // spaceBetween={'3%'}
                slidesPerView={1.4}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)} //Callback that receives Swiper instance
                breakpoints={{
                    600: {
                        slidesPerView: 2,
                    },
                    1050: {
                        slidesPerView: 3,
                    },
                }}
            >
                {
                    products?.slice(0,4)?.map((item,index) => {
                        return <SwiperSlide key={item?.id}>
                            <div className={'bestseller-item'} key={index}>
                                <ProductCard item={item}/>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </div>
    </div>
}export default BestSellerSlider