import './partners.scss'
import {useState,useEffect} from "react";
import PagesHeader from "../../components/pagesHeader/PagesHeader";
import {ArrWhiteIcon} from "../../assets/images";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "../../components/uiElements/input-group/InputGroup";
import {useGetPartnerQuery} from "../../redux/service/partners";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import {useJoinUsMutation} from "../../redux/service/joinUs";
import {useSelector} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";

function Partners(){
    const {data: partners} =  useGetPartnerQuery()
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const [joinUs,{ isLoading }] = useJoinUsMutation()

    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    })

    function validate () {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && !/^[+374]{4}[0-9]{8}$/.test(fieldsData[key]))) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function getInputValues (e) {
        let { name, value } = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (name === "phone" && value.length === 1 && value !== "+") {
            value = "+" + value
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const sendMessage = () => {
        const payload = {
            firstname: fieldsData.firstName,
            lastname: fieldsData.lastName,
            email: fieldsData.email,
            phone: fieldsData.phone,
        }
        if (validate()) {
            joinUs(payload).catch((error) => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })
            })
        }
    }

    return <div className={'partners-wrapper'}>
        <PagesHeader title={staticTexts?.partner_page_title}/>

        <div className={'partners-block'}>
            {
                partners?.map((item,index) => {
                    return <div className={'partner-item'} key={index}>
                        <div className={'partner-item-img'}>
                            <img src={generateMediaUrl(item?.icon?.path)} alt="img"/>
                        </div>
                        <a href={item?.link} target={'_blank'} className={'partners-hover-block'}>
                            <div className={"text-count"}>{staticTexts?.partner_page_hover_text}</div>
                            <ArrWhiteIcon/>
                        </a>
                    </div>
                })
            }
        </div>
        <div className={'partner-joinUs-block'}>
            <div className={'joinUs-text-block'}>
                <div className={'joinUs-title'}>
                    {staticTexts?.partner_page_join_title}
                </div>
                <div className={'joinUs-description'}>
                    {staticTexts?.partner_page_join_description}
                </div>
            </div>
            <div className={'joinUs-inputs-block'}>
                <div className={'joinUs-fullName-inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.firstName}
                        error={error.firstName}
                        name={'firstName'}
                        placeholder={staticTexts?.partner_page_join_firstName_placeholder}
                        label={staticTexts?.partner_page_join_firstName_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.lastName}
                        error={error.lastName}
                        name={'lastName'}
                        placeholder={staticTexts?.partner_page_join_lastName_placeholder}
                        label={staticTexts?.partner_page_join_lastName_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                </div>
                <div className={'joinUs-info-inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.email}
                        error={error.email}
                        name={'email'}
                        placeholder={staticTexts?.partner_page_join_email_placeholder}
                        label={staticTexts?.partner_page_join_email_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.phone}
                        error={error.phone}
                        name={'phone'}
                        placeholder={staticTexts?.partner_page_join_phone_placeholder}
                        label={staticTexts?.partner_page_join_phone_label}
                        maxLength={12}
                        onChange={getInputValues}
                    />
                </div>
                <button className={'joinUs-send-btn'} onClick={sendMessage}>
                    {staticTexts?.partner_page_join_send} {isLoading ?
                    <LoadingOutlined style={{ fontSize: 14, color: "#fff" }}/> : ''}
                </button>
            </div>
        </div>
    </div>

}export default Partners