import './mobileMenu.scss'
import {CloseIcon,FooterFacebookIcon, InstagramIcon} from "../../assets/images";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useGetContactQuery} from "../../redux/service/contact";


function MobileMenu(props){
    const {toggleMobileMenu,className,mobileMenuOpen} = props
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: contact} = useGetContactQuery(selectedLanguage)

    return <div className={`mobile-menu-wrapper ${className}`}>
        <div className={'left-menu-block'}>
            <div className={'menu-close-block'} onClick={toggleMobileMenu}>
                <CloseIcon/>
            </div>
            <div className={'menu-wrapper'}>
                <div className={'menu-title'}>
                    {staticTexts?.header_menu}
                </div>
                <Link to={'/'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_home} </Link>
                <Link to={'/brands'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_brands}</Link>
                <Link to={'/partners'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_partners}</Link>
                <Link to={'/blog'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_blog}</Link>
                <Link to={'/about'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_about}</Link>
                <Link to={'/contact'} onClick={toggleMobileMenu} className={`${mobileMenuOpen ? '' : 'overflow'}`}>{staticTexts?.header_item_contact}</Link>
            </div>
            <div className={'menu-connect-block'}>
                <div className={'connect-block-title'}>
                    {staticTexts?.header_item_connect}
                </div>
                <a className={'connect-email'} href={`mailto:${contact?.email}`}>
                    {contact?.email}
                </a>
                <div className={'connect-social-block'}>
                    <a href={contact?.facebook} target={'_blank'}>
                        <FooterFacebookIcon/>
                    </a>
                    <a href={contact?.instagram} target={'_blank'}>
                        <InstagramIcon/>
                    </a>
                </div>
            </div>
        </div>
    </div>

}export default MobileMenu