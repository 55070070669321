import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import {sliderApi} from "../service/slider";
import {aboutApi} from "../service/about";
import {brandTypeApi} from "../service/brand_type";
import {brandApi} from "../service/brand";
import {blogApi} from "../service/blog";
import {partnerApi} from "../service/partners";
import {joinUsApi} from "../service/joinUs";
import {filterApi} from "../service/filter";
import {productApi} from "../service/product";
import {languageApi} from "../service/language";
import {subscribeApi} from "../service/subscribe";

import languageReducer from '../slices/languageSlice';
import brandeReducer from '../slices/brandeSlice';
import productReducer from '../slices/productSlice';
import blogReducer from '../slices/blogSlice';
import pageReducer from '../slices/pageSlice';
import staticTextsReducer from '../slices/staticTextSlice';

// src/redux/store.js
import storage from 'redux-persist/lib/storage'
import {persistStore} from 'redux-persist';
import {contactApi} from "../service/contact";
import {staticTextsApi} from "../service/static-texts";

const persistConfig = {
    key: 'root',
    storage,
    whitelist:['language']
};

const rootReducer = combineReducers({
    language:languageReducer,
    brand: brandeReducer,
    product: productReducer,
    blog: blogReducer,
    page:pageReducer,
    staticTexts: staticTextsReducer,
    [sliderApi.reducerPath]: sliderApi.reducer,
    [aboutApi.reducerPath]: aboutApi.reducer,
    [brandTypeApi.reducerPath]: brandTypeApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [joinUsApi.reducerPath]: joinUsApi.reducer,
    [filterApi.reducerPath]: filterApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [languageApi.reducerPath]: languageApi.reducer,
    [subscribeApi.reducerPath]: subscribeApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [staticTextsApi.reducerPath]: staticTextsApi.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer, // Use the persisted reducer
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            sliderApi.middleware,
            aboutApi.middleware,
            brandTypeApi.middleware,
            brandApi.middleware,
            blogApi.middleware,
            partnerApi.middleware,
            joinUsApi.middleware,
            filterApi.middleware,
            productApi.middleware,
            languageApi.middleware,
            subscribeApi.middleware,
            contactApi.middleware,
            staticTextsApi.middleware,
        ),
});
const persistor = persistStore(store);
export {store, persistor};