import './ourBrands.scss'
import {ArrIcon, ArrWhiteIcon} from "../../../assets/images";
import {Link} from "react-router-dom";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {useGetBrandQuery} from "../../../redux/service/brand";
import {useSelector} from "react-redux";

function OurBrands({language,staticTexts}) {
    const brand = useSelector((state) =>  state.brand.brandeList)

    return <div className={'our-brands-wrapper'}>
        <div className={'brands-header'}>
            <div className={'brands-header-left'}>
                <div className={'brands-header-title'}>
                    {staticTexts?.home_brandes_title}
                </div>
                {/*<div className={'brands-header-description'}>*/}
                {/*    {staticTexts?.home_brandes_description}*/}
                {/*</div>*/}
            </div>
            <Link to={'/brands'} className={'brands-header-right'}>
                {staticTexts?.home_see_all}
                <ArrIcon/>
            </Link>
        </div>
        <div className={'our-brandes-block'}>
            {
                brand?.brands?.slice(0,6)?.map((item, index) => {
                    return <Link to={`/brands/${item?.slug}`}  className={'brands-item'} key={index}>
                        <div className={'brandes-img'}>
                            <img src={generateMediaUrl(item?.mediaMain?.path)} alt=""/>
                        </div>
                        <div className={'more-content'}>
                            <div className={"text-count"}>{item?.title}</div>
                            <ArrWhiteIcon/>
                        </div>
                    </Link>
                })
            }
            <Link to={'/brands'} className={'brands-all-responsive'}>
                {staticTexts?.home_see_all}
                <ArrIcon/>
            </Link>
        </div>
    </div>

}

export default OurBrands