import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const joinUsApi = createApi({
    reducerPath: 'joinUsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        joinUs: builder.mutation({
            query: (payload) => ({
                url: '/join-us',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const { useJoinUsMutation } = joinUsApi;
