import { createSlice } from '@reduxjs/toolkit';


export const pageSlice = createSlice({
    name: 'product',
    initialState: {
        productPage: 1,
    },
    reducers: {
        getPage: (state,action) => {
            state.productPage = action.payload;
        },
    },
});

export const {getPage} = pageSlice.actions;

export default pageSlice.reducer;
