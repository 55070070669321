import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({
            baseUrl: BASE_URL
        }
    ),
    endpoints: (builder) => ({
        getProduct: builder.query({
            query: ({language,brand,limit= 9,offset = 0,filter,category} = {}) =>({
                url: `/product${limit ? `?limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}${brand ? `&brand=${brand}` : ''}${filter?.length ? `&filter=${filter}` : ''}${category ? `&category=${category}` : ''}`,
                headers: {
                    "accept-language": language
                },
            }) ,
        }),
        getProductCount: builder.query({
            query: ({language,brand,filter,category} = {}) =>({
                url: `/product/count${brand ? `?brand=${brand}` : ''}${filter?.length ? `&filter=${filter}` : ''}${category ? `&category=${category}` : ''}`,
                headers: {
                    "accept-language": language
                },
            }) ,
        }),
        getProductById: builder.query({
            query: ({id, language} = {}) =>({
                url:`/product/${id}`,
                headers: {
                    "accept-language": language
                },
            }),
        }),
    }),
});

export const {useGetProductQuery,useGetProductByIdQuery,useGetProductCountQuery} = productApi;

