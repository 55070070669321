import { createSlice } from '@reduxjs/toolkit';


export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        selectedLanguage: 'en',
    },
    reducers: {
        changeLanguage: (state,action) => {
            state.selectedLanguage = action.payload;
        },
    },
});

export const { getLanguage, changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
