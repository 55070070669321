import { createSlice } from '@reduxjs/toolkit';


export const brandeSlice = createSlice({
    name: 'brand',
    initialState: {
        brandeList: [],
    },
    reducers: {
        getBrande: (state,action) => {
            state.brandeList = action.payload;
        },
    },
});

export const {getBrande } = brandeSlice.actions;

export default brandeSlice.reducer;
