import './blogs.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import {ArrIcon} from "../../../assets/images";
import BlogCard from "../../uiElements/cards/blogCard/BlogCard";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";


function Blog({staticTexts}) {
    const blogDate = useSelector((state) =>  state.blog.blogList)

    return <div className={'home-blog-wrapper'}>
        <div className={'home-blog-header'}>
            <div className={'home-blog-info'}>
                <div className={'home-blog-title'}>
                    {staticTexts?.home_blog_title}
                </div>
                <div className={'home-blog-description'}>
                    {staticTexts?.home_blog_description}
                </div>
            </div>
            <Link to={'/blog'} className={'home-blog-all'}>
                {staticTexts?.home_see_all}
                <ArrIcon/>
            </Link>
        </div>
        <div className={'home-blogs'}>
            <Swiper
                className={'membership-slider'}
                spaceBetween={'3%'}
                slidesPerView={1.2}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)} //Callback that receives Swiper instance
                breakpoints={{
                    600: {
                        slidesPerView: 2,
                    },
                    1050: {
                        slidesPerView: 3,
                    },
                }}
            >
                {
                    blogDate?.blogs?.length &&  blogDate?.blogs?.slice(0,3)?.map((item,index) => {
                        return <SwiperSlide key={index}>
                            <div className={'blog-item'} key={index}>
                                <BlogCard item={item}/>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>

            <Link to={'/blog'} className={'home-blog-all-responsive'}>
                {staticTexts?.home_see_all}
                <ArrIcon/>
            </Link>
        </div>
    </div>

}

export default Blog