import './category.scss'
import {Pagination} from "antd";
import {
    PageLeftArrIcon,
    PageRightArrIcon, Slider1Img, Slider2Img, Slider3Img,
} from "../../../assets/images";
import ProductCard from "../../../components/uiElements/cards/productCard/ProductCard";
import Partners from "../../../components/homepages/partners/Partners";
import {Link, useParams} from "react-router-dom";
import {useGetBrandByIdQuery} from "../../../redux/service/brand";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {useRef} from "react";
import {getPage} from "../../../redux/slices/pageSlice";

function Brand() {
    const [brandPage, setBrandPage] = useState(1);
    const mainRef = useRef(null);
    const param = useParams()
    const productPages = useSelector((state) => state.page.productPage)
    const [productPage, setProductPage] = useState(productPages);
    const dispatch = useDispatch()
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const {data: brandById} = useGetBrandByIdQuery({id:param?.slug,language:selectedLanguage})
    const sliderImg = [Slider3Img,Slider1Img,Slider2Img]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PageLeftArrIcon/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PageRightArrIcon/>
            </a>;
        }
        return originalElement;
    };

    const onChangePaging = (page) => {
        setProductPage(page)
        dispatch(getPage(page))
        window.scrollTo({
            top: mainRef?.current?.clientHeight,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[brandById?.id])


    return <div className={'product-wrapper'}>
        <Swiper
            slidesPerView={1}
            freeMode={true}
            modules={[Autoplay]}
            ref={mainRef}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={false}
        >
            {
                sliderImg.map(img => {
                    return <SwiperSlide>
                        <div className="slider-item" >
                            <div className={'products-header'}>
                                <img src={img} alt=""/>
                            </div>
                        </div>
                    </SwiperSlide>
                })
            }
        </Swiper>
        <div className={'product-block'}>
            <div className={'product-response-text'}>
                <div className={'product-title'}>
                    {brandById?.title}
                </div>
                <div className={'product-description'}  dangerouslySetInnerHTML={{__html: brandById?.description}} />
            </div>
            <div className={'product-list-block'}>
                <div className={'product-info'}>
                    <div className={'product-title'}>
                        {brandById?.title}
                    </div>
                    <div className={'product-description'}  dangerouslySetInnerHTML={{__html: brandById?.description}} />
                </div>
                {
                    brandById?.categories?.length ? <div className={'product-list'}>
                        <div className={'product-list-items'}>
                            {
                                brandById?.categories?.map((item, index) => {
                                    return <Link to={`/brands/${brandById?.slug}/category/${item?.category?.id}`} className={'product'} key={index}>
                                        <ProductCard item={item}/>
                                    </Link>
                                })
                            }
                        </div>
                            <Pagination current={productPage}
                                        defaultPageSize={9}
                                        onChange={onChangePaging}
                                        itemRender={itemRender}
                                        hideOnSinglePage={true}
                                        className={`pagination-product`}
                                        total={brandById?.categories?.length || 0}/>
                        </div> :
                        <div className={'product-empty-block'}>
                            {staticTexts?.product_empty_text}
                        </div>
                }
            </div>
        </div>
        <Partners/>
    </div>
}

export default Brand