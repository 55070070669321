//Homepage blocks
import MainSlider from "../../components/homepages/mainSlider/MainSlider";
import OurBrands from "../../components/homepages/ourBrands/OurBrands";
import AboutUs from "../../components/homepages/aboutUs/AboutUs";
import Partners from "../../components/homepages/partners/Partners";
import Blog from "../../components/homepages/blogs/Blogs";
import {useDispatch, useSelector} from "react-redux";
import {useGetBlogQuery} from "../../redux/service/blog";
import {useEffect} from "react";
import {getBlog} from "../../redux/slices/blogSlice";

export default function Homepage() {
    const dispatch = useDispatch()
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: blogDate} = useGetBlogQuery({language:selectedLanguage,limit:4})

    useEffect(() => {
        dispatch(getBlog(blogDate))
    },[blogDate])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'home-wrapper'}>
        <MainSlider language={selectedLanguage}/>
        <OurBrands staticTexts={staticTexts}
                   language={selectedLanguage}/>
        <AboutUs language={selectedLanguage}
                 staticTexts={staticTexts}/>
        <Partners language={selectedLanguage}
                  staticTexts={staticTexts}/>
        <Blog staticTexts={staticTexts}/>
    </div>
}
