import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const staticTextsApi = createApi({
    reducerPath: 'staticTextsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getStaticTexts: builder.query({
            query: () => `/static-texts`,
        })
    }),
});

export const { useGetStaticTextsQuery } = staticTextsApi;

