import { createSlice } from '@reduxjs/toolkit';


export const productSlice = createSlice({
    name: 'product',
    initialState: {
        bestSellerProduct: [],
    },
    reducers: {
        getProduct: (state,action) => {
            state.bestSellerProduct = action.payload?.filter(item => item?.bestSeller === true);
        },
    },
});

export const {getProduct } = productSlice.actions;

export default productSlice.reducer;
