import './blogCard.scss'
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";


function BlogCard({item}) {

    return <Link to={`/blog/${item?.slug}`} className={'blog-card-wrapper'}>
        <div className={'blog-card-img'}>
            <img src={generateMediaUrl(item?.mediaMain?.path)} alt=""/>
        </div>
        <div className={'blog-card-info'}>
            <div className={'blog-card-title'}>
                {item?.title}
            </div>
            <div className={'blog-card-description'}>
                {item?.description}
            </div>
        </div>
    </Link>

}

export default BlogCard