import './partners.scss'
import {useGetPartnerQuery} from "../../../redux/service/partners";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


function Partners({language}) {
    const {data: partners} =  useGetPartnerQuery(language)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)

    return <div className={'home-partners-wrapper'}>
        <div className={'home-partners-top-block'}>
            <div className={'home-partners-title'}>
                {staticTexts?.home_partners_title}
            </div>
            <div className={'home-partners-info'}>
                {/*<div className={'home-partners-description'}>*/}
                {/*    {staticTexts?.home_partners_description}*/}
                {/*</div>*/}
                <div className={'home-partners-btn'}>
                    <Link to={'/partners'} className={'home-partners-joinUs'}>
                        {staticTexts?.home_partners_join}
                    </Link>
                    <Link to={'/partners'} className={'home-partners-more'}>
                        {staticTexts?.home_partners_see}
                    </Link>
                </div>
            </div>
        </div>
        <div className={'home-partners-bottom-block'}>
            {
                partners?.map(item => {
                    return <div className="home-partners-item" key={item?.id} >
                        <div className={'home-partners-img'}>
                            <img src={generateMediaUrl(item?.icon?.path)} alt=""/>
                        </div>
                        <div className={'home-partners-hover-img'}>
                            <img src={generateMediaUrl(item?.mediaMain?.path)} alt=""/>
                        </div>
                    </div>
                })
            }
        </div>
    </div>

}

export default Partners