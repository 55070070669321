import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const partnerApi = createApi({
    reducerPath: 'partnerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getPartner: builder.query({
            query: (language) =>({
                url:`/partner`,
                headers: {
                    "accept-language": language
                },
            }),
        })
    }),
});

export const { useGetPartnerQuery } = partnerApi;

