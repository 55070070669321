import './about.scss'
import PagesHeader from "../../components/pagesHeader/PagesHeader";
import {useGetAboutQuery} from "../../redux/service/about";
import {useSelector} from "react-redux";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import Partners from "../../components/homepages/partners/Partners";
import {useEffect} from "react";


function About(){
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const {data: about} = useGetAboutQuery(selectedLanguage)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'about-page-wrapper'}>
        <PagesHeader title={`${staticTexts?.about_page_title}`}/>

        <div className={'aboutUs-block'}>
            <div className={'aboutUs-img-wrapper'}>
                <img src={generateMediaUrl(about?.mediaMain?.path)} alt=""/>
            </div>
            <div className={'about-info-block'}>
                <div className={'about-page-title'}>
                    {about?.title}
                </div>
                <div className={'about-page-description'}>
                    {about?.description}
                </div>
            </div>
        </div>
        <Partners/>
    </div>

}export default About