import './blogDetail.scss'
import {useSelector} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import BlogCard from "../../../components/uiElements/cards/blogCard/BlogCard";
import {useParams} from "react-router-dom";
import {useGetBlogByIdQuery} from "../../../redux/service/blog";
import {generateMediaUrl} from "../../../utils/generateMediaUrl";
import {BookIcon, CopyIcon, FACEBOOK_Icon, LINKEDIN_Icon, TWITTER_Icon} from "../../../assets/images";
import {useEffect} from "react";
import Subscribe from "../../../components/subscribe/Subscribe";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {WEB_PAGE_URL} from "../../../redux/api";
import {FacebookShareButton,TwitterShareButton,LinkedinShareButton} from "react-share";


function BlogDetail(){
    const params = useParams()
    const selectedLanguage = useSelector((state) =>  state.language.selectedLanguage)
    const staticTexts = useSelector((state) =>  state.staticTexts.staticTexts)
    const blogDate = useSelector((state) =>  state.blog.blogList)
    const {data: blogById} = useGetBlogByIdQuery({slug:params?.slug,language:selectedLanguage})
    const shareUrl = `${WEB_PAGE_URL}/blog/${params?.slug}`


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[params?.slug])

    return <div className={'blog-detail-wrapper'}>
        <div className={'blog-detail-img'}>
            <img src={generateMediaUrl(blogById?.coverMedia?.path)} alt=""/>
        </div>
        <div className={'blog-detail-bottom'}>
            <div className={'blog-detail-info'}>
                <div className={'blog-detail-title'}>
                    {blogById?.title}
                </div>
                <div className={'blog-social-wrapper'}>
                    <div className={'blog-duration-wrapper'}>
                        <div className={'blog-creator-block'}>
                            {blogById?.creator}
                        </div>
                        <span className={'blog-line'}/>
                        <div className={'blog-duration-block'}>
                            <BookIcon/> {blogById?.viewCount}
                        </div>
                    </div>
                    <div className={'blog-socials'}>
                        <FacebookShareButton url={shareUrl} quote={"Milanese"}  description={'Hello'}>
                            <p><FACEBOOK_Icon size={18} round={true}/></p>
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={'Milanese'}>
                            <p><TWITTER_Icon size={18} round={true}/> </p>
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl} title={'Milanese'}>
                            <p><LINKEDIN_Icon size={18} round={true}/> </p>
                        </LinkedinShareButton>
                        <CopyToClipboard text={shareUrl}>
                            <div onClick={e => {
                                e.stopPropagation()
                            }}
                                 className={'copy-share'}
                            >
                                <CopyIcon/>
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className={'blog-detail-description'}  dangerouslySetInnerHTML={{__html: blogById?.details}} />
            </div>
            <div className={'blog-detail-latest-block'}>
                <div className={'latest-block-title'}>
                    {staticTexts?.blog_detail_last_title}
                </div>
                <div className={'latest-block-description'}>
                    {staticTexts?.blog_detail_last_description}
                </div>
                <div className={'latest-article'}>

                    <Swiper
                        className={'membership-slider'}
                        spaceBetween={'3%'}
                        slidesPerView={1.2}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)} //Callback that receives Swiper instance
                        breakpoints={{
                            600: {
                                slidesPerView: 2,
                            },
                            1050: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {
                            blogDate?.blogs?.length &&  blogDate?.blogs?.filter(e => e.id !== params.id)?.map((item,index) => {
                                return <SwiperSlide key={index}>
                                    <div className={'blog-item'} key={index}>
                                        <BlogCard item={item}/>
                                    </div>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                </div>
            </div>

            <div className={'subscribe-wrapper'}>
                <Subscribe/>
            </div>
        </div>
    </div>

}export default BlogDetail