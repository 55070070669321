import './productCard.scss'
import {Link} from "react-router-dom";
import {generateMediaUrl} from "../../../../utils/generateMediaUrl";
import {PlaceholderImg} from "../../../../assets/images";

function ProductCard({item}){

    return <div className={'product-card'} onClick={() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }}>
        <div className={'product-card-img'}>
            <img src={item?.mediaMain?.path ? generateMediaUrl(item?.mediaMain?.path) : PlaceholderImg} alt=""/>
        </div>
        <div className={'product-card-info'}>
            <div className={'product-card-title'}>
                {item?.category ? item?.category.title : item?.title}
            </div>
            <div className={'product-card-description'}>
                {item?.subTitle}
            </div>
        </div>
    </div>
}export default ProductCard