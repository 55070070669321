import React from 'react';
import { BrowserRouter } from "react-router-dom";
import RoutesBlocks from "../src/routing";
import { Provider } from 'react-redux'
import {store,persistor} from "./redux/store/store";
import {PersistGate} from "redux-persist/integration/react";

function App () {
  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                  <RoutesBlocks/>
              </BrowserRouter>
          </PersistGate>
      </Provider>
  );
}

export default App;
