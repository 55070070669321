// Import packages
import React from "react";
import {Route, Routes} from "react-router-dom";
import Layout from "../containers/Layout";

// Import utils

// Import pages
import Brandes from "../containers/brands/Brandes";
import Product from "../containers/brands/catigoria/products/Product";
import Homepage from "../containers/Homepage/Homepage";
import ProductDetail from "../containers/brands/catigoria/products/productDetail/ProductDetail";
import Partners from "../containers/partners/Partners";
import Blog from "../containers/blog/Blog";
import BlogDetail from "../containers/blog/blogDetail/BlogDetail";
import About from "../containers/about/About";
import Contact from "../containers/contact/Contact";
import Brand from "../containers/brands/catigoria/Category";


export default function RoutesBlocks() {
    return <Layout>
        <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/brands" element={<Brandes/>}/>
            <Route path="/brands/:slug" element={<Brand/>}/>
            <Route path="/brands/:slug/category/:id" element={<Product/>}/>
            <Route path="/product/:id" element={<ProductDetail/>}/>
            <Route path="/partners" element={<Partners/>}/>
            <Route path="/blog" element={<Blog/>}/>
            <Route path="/blog/:slug" element={<BlogDetail/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
        </Routes>
    </Layout>
}


