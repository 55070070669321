import './pagesHeader.scss'
import {AnimationArrIcon, AnimationBgIcon, PagesIcon} from "../../assets/images";


function PagesHeader({title,description}){

    return <div className={'pages-header'}>
        <div className={'pages-info'}>
            <div className={'pages-title'}>
                {title}
            </div>
            <div className={'pages-description'}>
                {description}
            </div>
        </div>
        <div className={'pages-icon'}>
            <div className={'animationBg-icon'}>
                <AnimationBgIcon/>
            </div>
            <div className={'animationArr-icon'}>
                <AnimationArrIcon/>
            </div>
        </div>
    </div>

}export default PagesHeader