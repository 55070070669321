import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const contactApi = createApi({
    reducerPath: 'contactApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL }
    ),
    endpoints: (builder) => ({
        getContact: builder.query({
            query: (language) =>({
                url:`/contact`,
                headers: {
                    "accept-language": language
                },
            }) ,
        }),
        contact: builder.mutation({
            query: (payload) => ({
                url: '/contact',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const { useGetContactQuery,useContactMutation } = contactApi;

