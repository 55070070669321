import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const blogApi = createApi({
    reducerPath: 'blogApi',
    baseQuery: fetchBaseQuery({
            baseUrl: BASE_URL
        }
    ),
    endpoints: (builder) => ({
        getBlog: builder.query({
            query: ({limit = 3, offset = 0 ,language}= {}) =>({
                url:`/blog${limit ? `?limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}`,
                headers: {
                    "accept-language": language
                },
            }),
        }),
        getBlogById: builder.query({
            query: ({slug,language} = {}) =>({
                url:`/blog/${slug}`,
                headers: {
                    "accept-language": language
                },
            }) ,
        }),
    }),
});

export const {useGetBlogQuery, useGetBlogByIdQuery} = blogApi;

