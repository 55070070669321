import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_URL} from "../api";

export const brandApi = createApi({
    reducerPath: 'brandApi',
    baseQuery: fetchBaseQuery({
            baseUrl: BASE_URL
        }
    ),
    endpoints: (builder) => ({
        getBrand: builder.query({
            query: ({type = '', language,limit = 6, offset = 0} = {}) =>({
                url: `/brand${limit ? `?limit=${limit}` : ''}${offset ? `&offset=${offset}` : ''}${type ? `&type=${type}` : ''}`,
                headers: {
                    "accept-language": language
                },
            }),
        }),
        getBrandById: builder.query({
            query: ({id, language} = {}) => ({
                url: `/brand/${id}`,
                headers: {
                    "accept-language": language
                },
            }),
        }),
    }),
});

export const {useGetBrandQuery, useGetBrandByIdQuery} = brandApi;

